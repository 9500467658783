<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show[0]">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ $t("group.Create Group") }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form" style="height: 75vh">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        不知道怎么填？点这里
      </v-alert>
      <v-row align="center">
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("group.label.parent_directory") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-select
            :items="departmentTree"
            :label="root_department_id ? '' : '请选择'"
            item-text="name"
            item-value="id"
            v-model="root_department_id"
            required
            :rules="rules.notNullRules"
            @change="getvalue_typeSelected(root_department_id)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("group.label.name") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="group['name']"
            :placeholder="$t('group.placeholder.name')"
            :hint="$t('group.hint.name')"
            persistent-hint
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("group.label.external_id") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="group['external_id']"
            :placeholder="$t('group.placeholder.external_id')"
            :hint="$t('group.hint.external_id')"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader>{{ $t("group.label.description") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            rows="1"
            v-model="group['description']"
            :placeholder="$t('group.placeholder.description')"
            :hint="$t('group.hint.description')"
            persistent-hint
            :auto-grow="true"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="3"></v-col>
        <v-col cols="9" class="text-left">
          <v-btn elevation="4" medium color="primary" @click="create()"
            >创建</v-btn
          >
          <v-btn
            class="ml-6"
            elevation="0"
            medium
            color="gary"
            @click="resetForm()"
            >重置</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "GroupEditor",
  props: ["show", "departmentTree"],
  data() {
    return {
      group: {},
      valid: true,
      root_department_id: null,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      },
      // UI inter-communication
      show_datepicker: false
    };
  },
  methods: {
    getvalue_typeSelected(val) {
      this.root_department_id = val;
    },
    create() {
      if (this.$refs.form.validate()) {
        this.group["department_id"] = this.root_department_id;
        this.group["description"] = this.group["description"]
          ? this.group["description"]
          : "";
        this.$http
          .post(`api/group/create`, this.group)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            bus.$emit("refreshList");
            return "创建成功";
          })
          .catch(({ code, message }) => {
            throw `账号创建失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    }
  },
  watch: {
    watchShow(val) {
      if (val) {
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    watchShow() {
      return this.show[0];
    }
  }
};
</script>
