<template>
  <div class="user-wrapper">
    <Breadcrumbs>
      <template slot="action-buttons">
        <v-btn class="ml-3" depressed color="primary" @click="newCreate">
          {{ $t("group.Create Group") }}
        </v-btn>
      </template>
    </Breadcrumbs>

    <v-simple-table
      class="table-wrapper pr-12"
      style="position:relative;width:100%"
    >
      <template v-slot:default>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          style="position:absolute;top:20%;left:50%;margin-left:-16px;"
        ></v-progress-circular>
        <thead>
          <tr>
            <th class="text-left pl-4">
              外部ID
            </th>

            <th class="text-left">
              {{ $t("groups.title.name") }}
            </th>
            <th class="text-left">
              {{ $t("groups.title.department") }}
            </th>
            <th class="text-left">
              {{ $t("groups.title.external_id") }}
            </th>
            <th class="text-left">
              {{ $t("groups.title.account_count") }}
            </th>
            <th class="text-left">
              {{ $t("groups.title.source") }}
            </th>

            <th class="text-left">
              {{ $t("groups.title.description") }}
            </th>
            <th class="text-center" style="width:200px">
              操作
            </th>
          </tr>
        </thead>
        <tbody v-if="groups.length > 0">
          <tr v-for="group in groups" :key="group.id" class="text-left">
            <td class="pl-4">{{ group.external_id }}</td>
            <td>{{ group.name }}</td>
            <td>{{ group.department_name }}</td>
            <td>{{ group.external_id }}</td>
            <td>{{ group.account_count }}</td>
            <td>{{ group.source_name }}</td>
            <td>{{ group.description ? group.description : "-" }}</td>
            <td class="text-center">
              <v-btn icon elevation="0" :to="`/group/view/${group.id}`">
                <v-icon>
                  mdi-text-box-search-outline
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                @click.stop="
                  showGroupAccountsDrawer('GROUPACCOUNTDRAWER', group)
                "
              >
                <v-icon>
                  mdi-account-group-outline
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                title="移动用户组"
                @click.stop="
                  show_transfer_drawer('TRANSFERDRAWER', group.name, group.id)
                "
              >
                <v-icon>
                  mdi-cog-transfer-outline
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                color="red"
                @click.stop="deleteSource(group)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="8" style="width:100%;color:#7b7b7b">
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-if="Math.ceil(totalPage / limit) > 1"
      v-model="curPage"
      :length="Math.ceil(totalPage / limit)"
      total-visible="7"
      @input="onPageChange(curPage, limit)"
    ></v-pagination>

    <Component
      :is="sheet_editor"
      :is_readonly="is_readonly"
      :show="drawer_status"
      :entity_info="group"
      :is_modify="is_modify"
      :departmentTree="departmentTree"
    />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";
import CreateGroup from "@/views/group/drawer/CreateGroup";
import GroupEditor from "@/views/group/drawer/GroupEditor";
import GroupsAccountDrawer from "@/views/group/drawer/GroupsAccountDrawer";
import TransferDrawer from "@/views/group/drawer/TransferDrawer";

const EDITORS = {
  CREATEGROUP: CreateGroup,
  GROUPEDITOR: GroupEditor,
  GROUPACCOUNTDRAWER: GroupsAccountDrawer,
  TRANSFERDRAWER: TransferDrawer
};

export default {
  name: "AccountList",
  data() {
    return {
      sheet_editor: null,
      current_id: null,
      is_modify: null,
      show_confirm: null,
      is_readonly: null,
      groups: [],
      departmentTree: [],
      group: null,
      drawer_status: [false, false],
      //分页
      curPage: 1,
      limit: 10,
      totalPage: 0,
      loading: false
    };
  },
  created() {
    this.refreshList();
    this.getTree();
    bus.$off("refreshList").$on("refreshList", () => {
      this.onPageChange(this.curPage, 10);
    });
  },
  methods: {
    onPageChange(curPage, limit) {
      this.refreshList(curPage, limit);
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(`api/group/list?offset=${(curPage - 1) * limit}&limit=${limit}`)
        .delegateTo(api_request)
        .then(res => {
          this.groups = res.groups;
          this.totalPage = res.total;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    newCreate() {
      this.sheet_editor = EDITORS["CREATEGROUP"];
      this.is_modify = null;

      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    deleteSource(group) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除用户组 ${group.name} (#${group.id})？`,
          callback: () => {
            return this.$http
              .post(`api/group/${group.id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.curPage =
                  this.curPage > Math.ceil((this.totalPage - 1) / 10)
                    ? this.curPage - 1
                    : this.curPage;
                if (this.totalPage - 1 > 0) {
                  this.onPageChange(this.curPage, 10);
                } else {
                  this.refreshList();
                }
                return "账户已成功删除";
              })
              .catch(({ code, message }) => {
                throw `账户删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    showGroupAccountsDrawer(type, group) {
      this.sheet_editor = EDITORS[type];
      this.group = group;
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    show_transfer_drawer(type, name, id) {
      this.sheet_editor = EDITORS[type];
      this.group = {
        name,
        type: "group",
        id
      };
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    getTree() {
      this.$http
        .get("api/department/tree")
        .delegateTo(api_request)
        .then(data => {
          if (data.departments.length > 0) {
            this.departmentTree = data.departments.map(item => {
              item.name = item.name === "." ? "企业根目录" : item.name;
              return item;
            });
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox,
    GroupEditor,
    GroupsAccountDrawer,
    TransferDrawer
  }
};
</script>
<style lang="less" scoped>
.user-wrapper {
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    width: 96%;
  }
}

/deep/ .action-btn-group {
  margin-right: 26px;
}

/deep/.loginname .v-icon.v-icon {
  font-size: 16px;
}

/deep/ .v-input__prepend-outer {
  margin-right: 0 !important;
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
